@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"), url("./static/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: bold; }

.App {
  text-align: center;

  h1, h2, h3, p {
    font-weight: lighter;
    font-family: 'RobotoLight', sans-serif; } }

.gal-pic {
  img {
    max-width: 200px; } }

.text-italic {
  font-style: italic; }
